.btn-tel {
	background-color: red;
	display: inline-block;
	padding: .74em 1em;
	line-height: 1;
	border-radius: 4px;
	font-size: 2vw;
}
.btn {
	&:hover {
		transform: scale(1.1);
	}
}
.menu-btn {
	width: 3rem;
	height: 2rem;
	position: relative;
	z-index: 100000;
	overflow: hidden;
	cursor: pointer;
	.line {
		background-color: white;
		width: 100%;
		position: absolute;
		height: 2px;
		border-radius: 64px;
		transition: all .4s;
	}
	.line1 {
		top: 0;
		left: 0;
	}
	.line2 {
		@extend .centeringY;
		left: 0;
	}
	.line3 {
		bottom: 0;
		left: 0;
	}
	&.on {
		.line1 {
			top: 17px;
			left: -5px;
			width: calc(100% - 9px);
			transform: rotate(45deg);
		}
		.line2 {
			left: -3rem;
		}
		.line3 {
			bottom: 13px;
			left: -5px;
			transform: rotate(-45deg);
			width: calc(100% - 9px);
		}
	}
}

@include mq {
	.menu-btn.open {
		.line {
		}
		.line1 {
			top: 14px;
			left: 0px;
		}
		.line3 {
			bottom: 16px;
			left: 0px;
		}
	}
	.btn-tel {
		font-size: 7vw;
		font-weight: bold;
	}
}
