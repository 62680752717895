@charset "UTF-8";
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

*::before, *::after {
  display: block; }

html {
  width: 100%;
  height: 100%;
  font-size: 16px;
  background-color: #0F0600;
  color: #fff; }

body {
  margin: 0;
  font-size: 16px;
  color: white;
  font-family: ryo-text-plusn, "Yu Mincho", "YuMincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
  font-weight: 400;
  word-break: break-all;
  line-height: 1.6;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  background-color: #0F0600; }

a {
  transition: all .2s;
  text-decoration: none;
  color: #464646;
  cursor: pointer;
  display: block; }

a, span {
  color: inherit; }

ul, dl {
  list-style: none; }

input:hover {
  cursor: pointer; }

input, textarea, select {
  appearance: none;
  line-height: 1.6;
  border: none; }

input, textarea {
  display: inline-block;
  background: #fff;
  font-size: inherit;
  border-radius: 4px;
  width: 100%; }
  input::placeholder, textarea::placeholder {
    font-size: .9rem; }
  input:hover, textarea:hover {
    cursor: pointer; }

button {
  border: 0;
  outline: none;
  appearance: none;
  cursor: pointer;
  background: none; }

hr {
  border-color: #F0F0F0;
  border-width: 1px 0 0 0;
  margin: 1em auto; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

iframe {
  display: block;
  width: 100%;
  background-color: #F0F0F0; }

address {
  font-style: normal;
  margin-top: 2rem; }

table {
  width: 100%; }

strong {
  font-size: 1.25rem; }

address {
  margin: 0; }

rt {
  font-weight: normal;
  font-size: 1rem; }

.centering, .centering_fix, .theme-hr::after, .comp-thumbbox::after, .enkai .cnt, .footer-address .cnt, .loading .cnt, .title-box .inner {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; }
  .centeringY, .menu-btn .line2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .centeringX, .article, .menu-list::before, .menu-list::after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
  .centering_fix {
    position: fixed; }

.centering_fix {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.fiexed_100 {
  position: fixed;
  z-index: 9999;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%; }

.relative {
  position: relative; }

.inline {
  display: inline; }

.ib {
  display: inline-block; }

.fbc-wrap {
  display: flex;
  justify-content: center; }

.modaal-noscroll {
  overflow: auto !important; }

.dn {
  display: none; }

.pink {
  color: #FF99CC; }

.bg-red {
  background-color: #FF223C;
  color: #fff; }

.bg-green {
  background-color: #4FC994;
  color: #fff; }

.theme-hr {
  width: 100%;
  height: 2px;
  background: #EFEFEF;
  position: relative;
  z-index: 0;
  margin: 4rem auto; }
  .theme-hr::after {
    content: "";
    width: 16px;
    height: 16px;
    background: #000;
    border: 4px solid #fff;
    border-radius: 50%; }

.table {
  border-collapse: collapse; }
  .table th, .table td {
    padding: 1rem;
    border: 1px solid white; }
  .table th {
    width: 30%; }

.mb-visible {
  display: none; }

.img-wrap {
  margin: 2rem auto; }

.text-box {
  margin: 4rem auto; }

.comp-thumbbox {
  position: relative;
  z-index: 0;
  overflow: hidden; }
  .comp-thumbbox::before, .comp-thumbbox::after {
    transition: all .6s;
    pointer-events: none; }
  .comp-thumbbox::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.75); }
  .comp-thumbbox::after {
    content: "MORE →";
    z-index: 3;
    top: 70%;
    opacity: 0;
    padding: .5rem;
    border: 1px solid;
    letter-spacing: 1px;
    line-height: 1; }
  .comp-thumbbox:hover::before {
    height: 100%; }
  .comp-thumbbox:hover::after {
    opacity: 1;
    top: 50%; }

.figure img {
  width: 100%;
  margin: 1rem auto; }

@media screen and (max-width: 980px) {
  .mb-block {
    display: block; }
  .mb-visible {
    display: block; }
  .mb-hidden {
    display: none; } }

@media screen and (max-width: 600px) {
  .theme-hr {
    margin: 4rem auto; } }

.btn-tel {
  background-color: red;
  display: inline-block;
  padding: .74em 1em;
  line-height: 1;
  border-radius: 4px;
  font-size: 2vw; }

.btn:hover {
  transform: scale(1.1); }

.menu-btn {
  width: 3rem;
  height: 2rem;
  position: relative;
  z-index: 100000;
  overflow: hidden;
  cursor: pointer; }
  .menu-btn .line {
    background-color: white;
    width: 100%;
    position: absolute;
    height: 2px;
    border-radius: 64px;
    transition: all .4s; }
  .menu-btn .line1 {
    top: 0;
    left: 0; }
  .menu-btn .line2 {
    left: 0; }
  .menu-btn .line3 {
    bottom: 0;
    left: 0; }
  .menu-btn.on .line1 {
    top: 17px;
    left: -5px;
    width: calc(100% - 9px);
    transform: rotate(45deg); }
  .menu-btn.on .line2 {
    left: -3rem; }
  .menu-btn.on .line3 {
    bottom: 13px;
    left: -5px;
    transform: rotate(-45deg);
    width: calc(100% - 9px); }

@media screen and (max-width: 980px) {
  .menu-btn.open .line1 {
    top: 14px;
    left: 0px; }
  .menu-btn.open .line3 {
    bottom: 16px;
    left: 0px; }
  .btn-tel {
    font-size: 7vw;
    font-weight: bold; } }

.underline {
  text-decoration: underline; }

.mincho {
  font-family: ryo-text-plusn, "Yu Mincho", "YuMincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; }

.small {
  font-size: .75rem; }

.medium {
  font-size: 1.5rem; }

.large {
  font-size: 2rem; }

.bold {
  font-weight: bold; }

.green {
  color: #4FC994; }

.red {
  color: #FF223C; }

.attention {
  color: #F92828; }

.center {
  text-align: center; }

.right {
  text-align: right; }

.vertical {
  writing-mode: vertical-rl;
  white-space: nowrap;
  line-break: strict;
  text-align: justify;
  text-orientation: upright; }

.txt-tranform-capitalize {
  text-transform: capitalize; }

.bold {
  font-weight: bold; }

.link:hover {
  color: #4ac4ff; }

.link-inline {
  color: #4ac4ff;
  text-decoration: underline;
  display: inline;
  margin-right: .25rem;
  margin-left: .25rem; }

.link2:hover {
  text-decoration: underline; }

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .small {
    font-size: 1vw; } }

.title-main {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 4rem;
  overflow: hidden; }
  .title-main::before {
    content: "";
    background: url(../images/icon/svg/title-icon.svg) center no-repeat;
    width: 50px;
    height: 50px;
    margin: 0 auto 2rem; }

@media screen and (max-width: 980px) {
  .title-main {
    font-size: 2rem;
    text-align: center;
    margin: 4rem auto 2rem; }
    .title-main::before {
      width: 32px;
      height: 32px;
      margin-bottom: 1rem; } }

.enkai {
  background: url(../images/footer-enkai.jpg) center;
  background-size: cover;
  position: relative;
  padding: 12vw;
  margin-bottom: 8rem; }
  .enkai .cnt {
    width: 100%;
    padding: 1rem; }
    .enkai .cnt .title {
      font-size: 2.75vw;
      margin-bottom: 1vw;
      font-weight: normal; }
    .enkai .cnt .text {
      font-size: 1.25vw;
      margin-bottom: 2.25vw;
      font-family: futura-pt, ryo-gothic-plusn, "Yu Gothic", YuGothic, -apple-system, BlinkMacSystemFont, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Helvetica, sans-serif; }
    .enkai .cnt .s-text {
      font-size: 1.5vw;
      margin-bottom: 0.5vw;
      display: inline-block; }

.footer-cnt {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 8rem; }
  .footer-cnt .item1, .footer-cnt .item3 {
    flex-basis: 28%; }
  .footer-cnt .item2 {
    flex-grow: 1;
    margin: 0 4%; }
  .footer-cnt .item iframe {
    height: 19rem; }
  .footer-cnt .item img {
    width: 100%; }
  .footer-cnt .item .title {
    font-weight: normal;
    font-size: 2vw;
    border-left: 1px solid;
    border-right: 1px solid; }
  .footer-cnt .item .text {
    margin: 2rem auto; }

.holiday-titles {
  display: flex; }

.footer-address {
  background: url(../images/footer-addressbg.png) center;
  background-size: cover;
  position: relative;
  padding: 16vw; }
  .footer-address .cnt {
    width: 100%;
    padding: 1rem; }
    .footer-address .cnt .title {
      font-size: 2.75vw;
      margin-bottom: 1vw;
      font-weight: normal; }
    .footer-address .cnt .text {
      font-size: 1.25vw;
      margin-bottom: 2.25vw;
      font-family: futura-pt, ryo-gothic-plusn, "Yu Gothic", YuGothic, -apple-system, BlinkMacSystemFont, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Helvetica, sans-serif; }
    .footer-address .cnt .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 3rem; }
      .footer-address .cnt .logo .item-l {
        margin-right: 2rem; }
      .footer-address .cnt .logo .logo-text {
        font-size: 1.5rem; }

.address {
  margin-bottom: 2rem; }
  .address p {
    margin-bottom: .5rem; }
    .address p:last-child {
      margin-bottom: 0; }

@media screen and (max-width: 980px) {
  .footer-cnt {
    display: block; }
    .footer-cnt .item {
      margin: 0 0 2rem; }
      .footer-cnt .item:last-child {
        margin-bottom: 0; }
      .footer-cnt .item .title {
        font-size: 5vw; }
  .footer-address {
    padding: 0;
    height: 137vw; }
    .footer-address .cnt .logo {
      display: block; }
      .footer-address .cnt .logo .item-l {
        margin: 0 auto 2rem; }
      .footer-address .cnt .logo .item-r img {
        margin: auto; } }

.flex, .flex-50, .flex-70-30, .flex-40-60, .flex-60-40 {
  display: flex; }

.flex-wrap, .flex-wrap-32, .flex-wrap-48 {
  display: flex;
  flex-wrap: wrap; }

.ai-center {
  align-items: center; }

.flex-between, .flex-48, .flex-32, .flex-20-75, .flex-25-70, .flex-30-70 {
  display: flex;
  justify-content: space-between; }

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.flex-vertical {
  display: flex;
  flex-direction: column; }

.flex-column-between {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .flex-column-between .item2 {
    text-align: end; }

.flex-50 > .item {
  flex-basis: 50%; }

.flex-48 > .item {
  flex-basis: 48%; }

.flex-32 {
  flex-wrap: wrap; }
  .flex-32 > .item {
    flex-basis: 32%; }

.flex-70-30 > .item-l {
  flex-basis: 70%; }

.flex-70-30 > .item-r {
  flex-basis: 30%; }

.flex-40-60 > .item-l {
  flex-basis: 40%; }

.flex-40-60 > .item-r {
  flex-basis: 60%; }

.flex-60-40 > .item-l {
  flex-basis: 60%; }

.flex-60-40 > .item-r {
  flex-basis: 40%; }

.flex-20-75 > .item-l {
  flex-basis: 20%; }

.flex-20-75 > .item-r {
  flex-basis: 75%; }

.flex-25-70 > .item-l {
  flex-basis: 25%; }

.flex-25-70 > .item-r {
  flex-basis: 70%; }

.flex-30-70 > .item-l {
  flex-basis: 30%; }

.flex-30-70 > .item-r {
  flex-basis: 70%; }

.flex-wrap-32 .item {
  flex-basis: 32%;
  margin-right: 2%;
  margin-bottom: 2%; }
  .flex-wrap-32 .item:nth-child(3n) {
    margin-right: 0; }

.flex-wrap-48 .item {
  flex-basis: 48%;
  margin-right: 4%;
  margin-bottom: 4%; }
  .flex-wrap-48 .item:nth-child(2n) {
    margin-right: 0; }

.flex-wrap-48.v2 .item:first-child {
  flex-basis: 100%; }

.flex-wrap-48.v2 .item:nth-child(2n) {
  margin-right: 4%; }

.flex-wrap-48.v2 .item:nth-child(odd) {
  margin-right: 0; }

.display-table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1px;
  border-bottom: 1px solid #eaeaea; }
  .display-table .cell {
    display: table-cell;
    padding: 1rem; }
    .display-table .cell:first-child {
      width: 8rem; }

.between {
  display: flex;
  justify-content: space-between; }

.block {
  display: block; }

.wrapper, .wrapper0 {
  width: 1200px;
  max-width: 90%;
  margin: 0 auto; }

.section {
  margin: 7rem auto; }

.section2 {
  margin: 2rem auto; }

.wrapper0 {
  padding: 0 1rem; }

.figure figcaption {
  padding: 1rem;
  line-height: 2; }

.scroll-wrap {
  overflow: auto; }

@media screen and (min-width: 1600px) {
  .wrapper, .wrapper0 {
    width: 1600px;
    max-width: 90%; } }

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .wrapper, .wrapper0 {
    width: 1400px;
    max-width: 90%; } }

@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .wrapper, .wrapper0 {
    width: 1400px;
    max-width: 90%; } }

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .wrapper, .wrapper0 {
    width: calc(1000px + 2rem);
    max-width: 80%; } }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .wrapper, .wrapper0 {
    max-width: 90%;
    width: 100%; } }

@media screen and (max-width: 600px) {
  .flex-50, .flex-wrap-48, .flex-48, .flex-wrap-32 {
    display: block; }
    .flex-50 .item, .flex-wrap-48 .item, .flex-48 .item, .flex-wrap-32 .item {
      margin-right: 0; }
  .section {
    margin: 3rem auto; }
  .wrapper, .wrapper0 {
    max-width: calc(100% - 2rem);
    padding: 0; } }

.header {
  background-image: url(../images/hero.jpg);
  background-position: center;
  background-size: cover;
  height: 100vh;
  transition: all .4s;
  padding-top: 3rem;
  position: relative;
  margin-bottom: 10%; }

.header-menu {
  margin-right: 3rem;
  font-size: 1.5rem; }
  .header-menu .menu-item {
    margin: 0 1rem; }

.header-submenu {
  font-size: 1rem;
  margin-top: 1rem; }
  .header-submenu a {
    margin: 0 .5rem; }
    .header-submenu a::before {
      content: "";
      display: inline-block;
      width: 1px;
      height: .5rem;
      margin-bottom: .5rem;
      background-color: #fff; }

.article {
  position: absolute;
  bottom: -10%;
  width: 800px; }
  .article .title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    text-shadow: 1px 1px 6px #000;
    letter-spacing: 2px; }

.article-cnt {
  align-items: center; }

.article-container {
  background: #fff;
  color: #000;
  padding: 1.5rem; }

.article-thumb {
  background-position: center;
  background-size: cover;
  margin-right: 1rem;
  min-height: 12rem; }

.article-title {
  font-size: 2.5rem;
  line-height: 1.4;
  font-family: futura-pt, ryo-gothic-plusn, "Yu Gothic", YuGothic, -apple-system, BlinkMacSystemFont, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Helvetica, sans-serif;
  font-weight: normal; }

.info {
  font-size: .75rem;
  font-family: futura-pt, ryo-gothic-plusn, "Yu Gothic", YuGothic, -apple-system, BlinkMacSystemFont, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Helvetica, sans-serif;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem; }
  .info .item {
    line-height: 1;
    padding: 0.5rem;
    margin-right: .5rem;
    margin-bottom: .5rem;
    color: #313131;
    background: rgba(255, 255, 255, 0.8); }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .header-container {
    margin: 0 auto; }
  .header-menu {
    margin-right: 2rem;
    margin-left: 2rem;
    font-size: 2.5vw; }
  .header {
    height: 50vw;
    position: static; } }

@media screen and (max-width: 980px) {
  .sns-fix {
    display: none; }
  .hero .item2 {
    width: 100%; }
    .hero .item2 .inner .btn-wrap {
      margin: 0; }
  .hero-cnt {
    margin: 2rem auto; }
  .header {
    height: 70vh;
    padding: 2rem;
    background-image: url(../images/hero2.jpg); }
  .header-container {
    max-width: 100%; }
  .mobile-header {
    background: #0F0600;
    padding: 7vw;
    transition: all .6s;
    transform: translateX(-100vw);
    white-space: normal; }
    .mobile-header img {
      width: 100%; }
    .mobile-header.on {
      transform: translateX(0); }
    .mobile-header .header-menu {
      margin-bottom: 1rem;
      list-style: disc;
      margin-left: 1.8rem; }
      .mobile-header .header-menu .menu-item {
        margin: 1rem auto; }
    .mobile-header .info {
      margin: 1rem auto; }
  .header-submenu a::before {
    width: .75rem;
    height: 1px;
    margin-bottom: .5rem;
    position: relative;
    top: 3px;
    margin-right: 1rem; }
  .header-submenu .sm-item {
    margin: .5rem auto; }
    .header-submenu .sm-item:last-child {
      margin-bottom: 0; }
  .header-bg--container {
    padding: 1.5rem 0; }
  .mb-menu-wrap {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100vw);
    width: 100%;
    height: 100%;
    background: rgba(13, 15, 37, 0.98);
    z-index: 99999;
    transition: all .6s;
    padding: 2rem;
    overflow: auto; }
    .mb-menu-wrap.open {
      transform: translateX(0); }
  .article {
    width: 100%;
    position: static;
    transform: none; }
  .article-title {
    font-size: 1.25rem; }
  .article-cnt {
    display: block; }
  .article-thumb {
    margin-right: 0;
    margin-bottom: 1rem; } }

.fade-in, .fade-in-right, .text-fade-in {
  opacity: 0;
  position: relative;
  z-index: 1;
  transform: translateY(2rem);
  transition: all 1s; }
  .fade-in.on, .on.fade-in-right, .on.text-fade-in {
    opacity: 1;
    transform: translateY(0); }

.fade-in-right {
  transform: translateX(2rem); }
  .fade-in-right.on {
    transform: translateX(0); }

.text-fade-in {
  display: inline-block;
  letter-spacing: 14px; }
  .text-fade-in.on {
    letter-spacing: 2px; }

.slide-in, .text-slide-in {
  opacity: 0;
  position: relative;
  transform: translateX(-2rem);
  transition: all 1s; }
  .slide-in.on, .on.text-slide-in {
    opacity: 1;
    transform: translateX(0); }

.slide-in-wrapper .item:nth-child(1) .text-slide-in {
  transition-delay: 0.2s; }

.slide-in-wrapper .item:nth-child(2) .text-slide-in {
  transition-delay: 0.4s; }

.slide-in-wrapper .item:nth-child(3) .text-slide-in {
  transition-delay: 0.6s; }

.slide-in-wrapper .item:nth-child(4) .text-slide-in {
  transition-delay: 0.8s; }

.slide-in-wrapper .item:nth-child(5) .text-slide-in {
  transition-delay: 1s; }

.slide-in-wrapper .item:nth-child(6) .text-slide-in {
  transition-delay: 1.2s; }

.slide-in-wrapper .item:nth-child(7) .text-slide-in {
  transition-delay: 1.4s; }

.slide-in-wrapper .item:nth-child(8) .text-slide-in {
  transition-delay: 1.6s; }

.slide-in-wrapper .item:nth-child(9) .text-slide-in {
  transition-delay: 1.8s; }

.slide-in-wrapper .item:nth-child(10) .text-slide-in {
  transition-delay: 2s; }

.slide-in-wrapper .item:nth-child(11) .text-slide-in {
  transition-delay: 2.2s; }

.slide-in-wrapper .item:nth-child(12) .text-slide-in {
  transition-delay: 2.4s; }

.slide-in-wrapper .item:nth-child(13) .text-slide-in {
  transition-delay: 2.6s; }

.slide-in-wrapper .item:nth-child(14) .text-slide-in {
  transition-delay: 2.8s; }

.slide-in-wrapper .item:nth-child(15) .text-slide-in {
  transition-delay: 3s; }

.slide-in-wrapper .item:nth-child(16) .text-slide-in {
  transition-delay: 3.2s; }

.slide-in-wrapper .item:nth-child(17) .text-slide-in {
  transition-delay: 3.4s; }

.slide-in-wrapper .item:nth-child(18) .text-slide-in {
  transition-delay: 3.6s; }

.slide-in-wrapper .item:nth-child(19) .text-slide-in {
  transition-delay: 3.8s; }

.slide-in-wrapper .item:nth-child(20) .text-slide-in {
  transition-delay: 4s; }

.slide-in-wrapper .item:nth-child(21) .text-slide-in {
  transition-delay: 4.2s; }

.slide-in-wrapper .item:nth-child(22) .text-slide-in {
  transition-delay: 4.4s; }

.slide-in-wrapper .item:nth-child(23) .text-slide-in {
  transition-delay: 4.6s; }

.slide-in-wrapper .item:nth-child(24) .text-slide-in {
  transition-delay: 4.8s; }

.slide-in-wrapper .item:nth-child(25) .text-slide-in {
  transition-delay: 5s; }

.slide-in-wrapper .item:nth-child(26) .text-slide-in {
  transition-delay: 5.2s; }

.slide-in-wrapper .item:nth-child(27) .text-slide-in {
  transition-delay: 5.4s; }

.slide-in-wrapper .item:nth-child(28) .text-slide-in {
  transition-delay: 5.6s; }

.slide-in-wrapper .item:nth-child(29) .text-slide-in {
  transition-delay: 5.8s; }

.slide-in-wrapper .item:nth-child(30) .text-slide-in {
  transition-delay: 6s; }

.slide-in-wrapper .item:nth-child(31) .text-slide-in {
  transition-delay: 6.2s; }

.slide-in-wrapper .item:nth-child(32) .text-slide-in {
  transition-delay: 6.4s; }

.slide-in-wrapper .item:nth-child(33) .text-slide-in {
  transition-delay: 6.6s; }

.slide-in-wrapper .item:nth-child(34) .text-slide-in {
  transition-delay: 6.8s; }

.slide-in-wrapper .item:nth-child(35) .text-slide-in {
  transition-delay: 7s; }

.slide-in-wrapper .item:nth-child(36) .text-slide-in {
  transition-delay: 7.2s; }

.slide-in-wrapper .item:nth-child(37) .text-slide-in {
  transition-delay: 7.4s; }

.slide-in-wrapper .item:nth-child(38) .text-slide-in {
  transition-delay: 7.6s; }

.slide-in-wrapper .item:nth-child(39) .text-slide-in {
  transition-delay: 7.8s; }

.slide-in-wrapper .item:nth-child(40) .text-slide-in {
  transition-delay: 8s; }

.slide-in-wrapper .item:nth-child(41) .text-slide-in {
  transition-delay: 8.2s; }

.slide-in-wrapper .item:nth-child(42) .text-slide-in {
  transition-delay: 8.4s; }

.slide-in-wrapper .item:nth-child(43) .text-slide-in {
  transition-delay: 8.6s; }

.slide-in-wrapper .item:nth-child(44) .text-slide-in {
  transition-delay: 8.8s; }

.slide-in-wrapper .item:nth-child(45) .text-slide-in {
  transition-delay: 9s; }

.slide-in-wrapper .item:nth-child(46) .text-slide-in {
  transition-delay: 9.2s; }

.slide-in-wrapper .item:nth-child(47) .text-slide-in {
  transition-delay: 9.4s; }

.slide-in-wrapper .item:nth-child(48) .text-slide-in {
  transition-delay: 9.6s; }

.slide-in-wrapper .item:nth-child(49) .text-slide-in {
  transition-delay: 9.8s; }

.slide-in-wrapper .item:nth-child(50) .text-slide-in {
  transition-delay: 10s; }

.slide-in-wrapper .item:nth-child(51) .text-slide-in {
  transition-delay: 10.2s; }

.slide-in-wrapper .item:nth-child(52) .text-slide-in {
  transition-delay: 10.4s; }

.slide-in-wrapper .item:nth-child(53) .text-slide-in {
  transition-delay: 10.6s; }

.slide-in-wrapper .item:nth-child(54) .text-slide-in {
  transition-delay: 10.8s; }

.slide-in-wrapper .item:nth-child(55) .text-slide-in {
  transition-delay: 11s; }

.slide-in-wrapper .item:nth-child(56) .text-slide-in {
  transition-delay: 11.2s; }

.slide-in-wrapper .item:nth-child(57) .text-slide-in {
  transition-delay: 11.4s; }

.slide-in-wrapper .item:nth-child(58) .text-slide-in {
  transition-delay: 11.6s; }

.slide-in-wrapper .item:nth-child(59) .text-slide-in {
  transition-delay: 11.8s; }

.slide-in-wrapper .item:nth-child(60) .text-slide-in {
  transition-delay: 12s; }

.slide-in-wrapper .item:nth-child(61) .text-slide-in {
  transition-delay: 12.2s; }

.slide-in-wrapper .item:nth-child(62) .text-slide-in {
  transition-delay: 12.4s; }

.slide-in-wrapper .item:nth-child(63) .text-slide-in {
  transition-delay: 12.6s; }

.slide-in-wrapper .item:nth-child(64) .text-slide-in {
  transition-delay: 12.8s; }

.slide-in-wrapper .item:nth-child(65) .text-slide-in {
  transition-delay: 13s; }

.slide-in-wrapper .item:nth-child(66) .text-slide-in {
  transition-delay: 13.2s; }

.slide-in-wrapper .item:nth-child(67) .text-slide-in {
  transition-delay: 13.4s; }

.slide-in-wrapper .item:nth-child(68) .text-slide-in {
  transition-delay: 13.6s; }

.slide-in-wrapper .item:nth-child(69) .text-slide-in {
  transition-delay: 13.8s; }

.slide-in-wrapper .item:nth-child(70) .text-slide-in {
  transition-delay: 14s; }

.slide-in-wrapper .item:nth-child(71) .text-slide-in {
  transition-delay: 14.2s; }

.slide-in-wrapper .item:nth-child(72) .text-slide-in {
  transition-delay: 14.4s; }

.slide-in-wrapper .item:nth-child(73) .text-slide-in {
  transition-delay: 14.6s; }

.slide-in-wrapper .item:nth-child(74) .text-slide-in {
  transition-delay: 14.8s; }

.slide-in-wrapper .item:nth-child(75) .text-slide-in {
  transition-delay: 15s; }

.slide-in-wrapper .item:nth-child(76) .text-slide-in {
  transition-delay: 15.2s; }

.slide-in-wrapper .item:nth-child(77) .text-slide-in {
  transition-delay: 15.4s; }

.slide-in-wrapper .item:nth-child(78) .text-slide-in {
  transition-delay: 15.6s; }

.slide-in-wrapper .item:nth-child(79) .text-slide-in {
  transition-delay: 15.8s; }

.slide-in-wrapper .item:nth-child(80) .text-slide-in {
  transition-delay: 16s; }

.slide-in-wrapper .item:nth-child(81) .text-slide-in {
  transition-delay: 16.2s; }

.slide-in-wrapper .item:nth-child(82) .text-slide-in {
  transition-delay: 16.4s; }

.slide-in-wrapper .item:nth-child(83) .text-slide-in {
  transition-delay: 16.6s; }

.slide-in-wrapper .item:nth-child(84) .text-slide-in {
  transition-delay: 16.8s; }

.slide-in-wrapper .item:nth-child(85) .text-slide-in {
  transition-delay: 17s; }

.slide-in-wrapper .item:nth-child(86) .text-slide-in {
  transition-delay: 17.2s; }

.slide-in-wrapper .item:nth-child(87) .text-slide-in {
  transition-delay: 17.4s; }

.slide-in-wrapper .item:nth-child(88) .text-slide-in {
  transition-delay: 17.6s; }

.slide-in-wrapper .item:nth-child(89) .text-slide-in {
  transition-delay: 17.8s; }

.slide-in-wrapper .item:nth-child(90) .text-slide-in {
  transition-delay: 18s; }

.slide-in-wrapper .item:nth-child(91) .text-slide-in {
  transition-delay: 18.2s; }

.slide-in-wrapper .item:nth-child(92) .text-slide-in {
  transition-delay: 18.4s; }

.slide-in-wrapper .item:nth-child(93) .text-slide-in {
  transition-delay: 18.6s; }

.slide-in-wrapper .item:nth-child(94) .text-slide-in {
  transition-delay: 18.8s; }

.slide-in-wrapper .item:nth-child(95) .text-slide-in {
  transition-delay: 19s; }

.slide-in-wrapper .item:nth-child(96) .text-slide-in {
  transition-delay: 19.2s; }

.slide-in-wrapper .item:nth-child(97) .text-slide-in {
  transition-delay: 19.4s; }

.slide-in-wrapper .item:nth-child(98) .text-slide-in {
  transition-delay: 19.6s; }

.slide-in-wrapper .item:nth-child(99) .text-slide-in {
  transition-delay: 19.8s; }

.slide-in-wrapper .item:nth-child(100) .text-slide-in {
  transition-delay: 20s; }

@media screen and (max-width: 980px) {
  .text-fade-in {
    letter-spacing: 0;
    display: block; }
    .text-fade-in.on {
      letter-spacing: 0; } }

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0f0600;
  z-index: 1000000; }
  .loading.open {
    top: -100%; }
  .loading .cnt {
    width: 25rem;
    max-width: 70%; }

.loading-logo img {
  margin: 0 auto; }

#loadingContainer {
  margin: 20px auto;
  width: 92%;
  height: 2px;
  position: relative; }

.progressbar-text {
  text-align: center;
  width: 100%;
  margin-top: 1.5rem !important; }

.curtain {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  transition: all 1s 1s;
  z-index: 9998; }
  .curtain.open {
    top: -100%; }

@media screen and (max-width: 980px) {
  .pace {
    display: none; } }

.modaal-overlay {
  background-color: rgba(35, 37, 56, 0.95) !important;
  opacity: 1 !important; }

.modaal-container {
  width: 1200px !important;
  max-width: 90% !important;
  color: #fff !important;
  background: none !important;
  box-shadow: none !important; }

#highlightModal, #artistModal {
  display: none; }

.modaal-content-container {
  padding: 0 !important; }

.modal-sns {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 1rem; }
  .modal-sns .item {
    margin-right: 1rem; }
    .modal-sns .item:last-child {
      margin: 0; }

.modal-content p {
  margin: 2rem auto; }

.modal-content img {
  width: 100%; }

.modal-content .cnt {
  margin: 4rem; }
  .modal-content .cnt .title {
    font-size: 4rem;
    margin-bottom: 1rem;
    margin-top: -3rem; }

.modal-content .vertical {
  white-space: normal;
  height: 28rem;
  text-align: left;
  display: inline-block;
  margin-bottom: 4rem; }
  .modal-content .vertical:last-child {
    margin-bottom: 0; }
  .modal-content .vertical p {
    margin: 0 1rem;
    font-size: 1.25rem;
    line-height: 2;
    letter-spacing: 1px; }
  .modal-content .vertical .title {
    font-size: 3rem;
    margin-left: 5rem;
    letter-spacing: 6px;
    padding-left: 1rem;
    border-left: 1px solid; }
  .modal-content .vertical .stxt {
    margin-left: 0; }

.modal-content .vertical-box {
  text-align: center;
  margin: 6rem auto; }

@media screen and (max-width: 980px) {
  .modaal-container {
    width: 100% !important; }
  .modal-content .cnt {
    margin: 0; }
    .modal-content .cnt .title {
      font-size: 3rem;
      margin-top: -2rem;
      line-height: 1.4; }
  .modal-content .vertical-box {
    margin: 2rem auto; }
  .modal-content .vertical {
    writing-mode: initial; }
    .modal-content .vertical p {
      margin: 1rem auto;
      font-size: 1rem; }
    .modal-content .vertical .title {
      font-size: 3rem;
      margin: 0rem auto 2rem;
      letter-spacing: 6px;
      padding-left: 0;
      border-left: none; }
    .modal-content .vertical .stxt {
      margin: 0; } }

.tab {
  display: flex;
  margin: 0 auto !important; }
  .tab .item {
    cursor: pointer;
    padding: .5rem 1rem; }
    .tab .item.select {
      background-color: #F0F0F0; }
    .tab .item .title {
      margin: 0;
      font-size: .9rem; }

.tab-box {
  background-color: #F0F0F0;
  padding: 2rem; }
  .tab-box .item {
    display: none; }
    .tab-box .item.select {
      display: block; }
    .tab-box .item .title {
      color: #464646;
      font-weight: normal;
      margin-bottom: 1.5rem; }
    .tab-box .item .text {
      margin: 0; }
    .tab-box .item .content {
      margin-bottom: 2.5rem; }

.tab-cnt-wrapper {
  margin: 3rem auto; }

.tab-wrap {
  overflow: auto; }

@media screen and (max-width: 980px) {
  .tab {
    width: 41rem; } }

.tablepress td {
  padding: 1rem !important;
  border: 1px solid #fff !important; }

.tablepress iframe {
  margin-top: 1rem; }

.tablepress img {
  max-width: 100% !important; }

.tablepress .column-1 {
  width: 20%;
  text-align: center; }

@media screen and (max-width: 980px) {
  .tablepress .column-1 {
    width: 100%;
    text-align: center;
    display: block;
    background-color: #ffffff;
    color: black; }
  .tablepress .column-2 {
    display: block;
    width: 100%; } }

.flex-gird {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  order: 3; }

.fg-item2 {
  flex-basis: 61%; }
  .fg-item2 .about-txt {
    width: 100%;
    padding: 3rem; }
  .fg-item2 .img2 {
    background-image: url(../images/section/about/img2.jpg);
    background-position: center;
    background-size: cover;
    padding-top: 41%; }

.fg-item3 {
  flex-basis: 100%;
  margin-top: 4%; }

#about {
  margin: 16rem auto; }

.about {
  display: flex;
  justify-content: center;
  margin-bottom: 10rem; }
  .about .item-r {
    margin: 0 4rem; }
    .about .item-r .title {
      font-size: 3rem;
      letter-spacing: .4vw;
      font-weight: normal;
      margin: 0 4rem; }
  .about .item-l {
    flex-basis: 50%; }

.about-cnt img {
  margin-top: 6rem;
  margin-bottom: 2rem; }

.about-cnt .caption {
  line-height: 2;
  letter-spacing: 1px;
  font-size: 1.1rem; }

.about-txt {
  background-image: url(../images/section/about/bg.jpg);
  margin-bottom: 7%;
  color: #333;
  height: 53%;
  white-space: normal; }
  .about-txt p {
    font-size: 1.5rem;
    margin: 0 1rem;
    line-height: 2; }
  .about-txt .title {
    font-size: 2.5rem;
    letter-spacing: 2px;
    margin-left: 1.5rem; }

.about2 {
  max-width: 1080px;
  margin: 0 auto;
  align-items: center; }
  .about2 .title {
    font-size: 1.8vw;
    font-weight: normal;
    margin-bottom: 1rem;
    letter-spacing: 4px; }
  .about2 .caption {
    line-height: 2;
    letter-spacing: 1px;
    font-size: 1.1rem; }
  .about2 .item-l {
    margin-right: 4rem; }

.fg-i3-item1 {
  flex-basis: 35%; }
  .fg-i3-item1.img1 {
    background-image: url(../images/section/about/img3.jpg);
    background-position: center;
    background-size: cover;
    padding-top: 20%; }

.fg-i3-item2 {
  flex-grow: 1; }
  .fg-i3-item2.img2 {
    background-image: url(../images/section/about/img4.jpg);
    background-position: center;
    background-size: cover;
    margin: 0 4%; }

.fg-i3-item3 {
  flex-basis: 35%; }
  .fg-i3-item3.img3 {
    background-image: url(../images/section/about/img5.jpg);
    background-position: center;
    background-size: cover; }

.title-box-wrapper {
  margin: 24rem auto 6rem; }

.title-box {
  position: relative;
  margin: 12rem auto; }
  .title-box::after {
    content: "";
    height: 1px;
    background-color: #fff; }
  .title-box .inner {
    background-color: #0F0600;
    padding: 4rem; }
    .title-box .inner .title {
      font-weight: normal; }
      .title-box .inner .title span {
        margin: 2rem auto 1rem;
        display: block;
        font-size: 3rem; }
      .title-box .inner .title::before {
        content: "";
        background-image: url(../images/parts/title-icon.svg);
        background-position: center;
        background-size: contain;
        width: 6rem;
        height: 6rem;
        margin: 0 auto; }
      .title-box .inner .title::after {
        content: "menu"; }
      .title-box .inner .title.seat::after {
        content: "seat"; }

.menu-image-box {
  position: relative;
  margin: 15rem auto; }

.menu-title {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 4rem;
  font-weight: normal;
  position: relative;
  z-index: 2;
  top: 4rem; }
  .menu-title::before {
    content: "";
    width: 7rem;
    height: 175%;
    border: 1px solid #fff;
    position: absolute;
    top: 44%;
    left: 49.5%;
    transform: translate(-50%, -50%); }
  .menu-title::after {
    content: "";
    width: 7rem;
    height: 175%;
    border: 1px solid #fff;
    position: absolute;
    top: 55.5%;
    left: 50.5%;
    transform: translate(-50%, -50%); }

.menu-list {
  border: 1px solid;
  padding: 6rem 4rem;
  position: relative;
  letter-spacing: 4px; }
  .menu-list::before {
    content: "";
    width: 2px;
    height: 4rem;
    background-color: #fff;
    top: -30px; }
  .menu-list::after {
    content: "";
    width: 2px;
    height: 4rem;
    background-color: #fff;
    bottom: -32px; }
  .menu-list .en-title img {
    margin: auto;
    width: 9rem; }

.slider {
  margin-bottom: 6rem; }

.menu-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  order: 3;
  margin-top: 2rem; }
  .menu-box .title {
    font-size: 2.5rem;
    display: inline-block;
    line-height: 1;
    border-right: 1px solid #fff;
    padding-right: 1rem;
    margin-right: 1rem;
    font-weight: normal; }
  .menu-box .list {
    margin: 2rem;
    font-size: 1rem;
    list-style: disc; }
    .menu-box .list .item {
      margin: 0 .5rem; }
  .menu-box .cnt {
    height: 90%; }
    .menu-box .cnt.sashimi {
      height: 20rem; }
  .menu-box > .item {
    margin: 2rem 0; }
    .menu-box > .item:first-child .list {
      margin-left: 0; }
    .menu-box > .item:last-child .title {
      margin-right: 0; }

.drink .menu-box .cnt {
  height: 42rem; }

.seat {
  margin-bottom: 12rem; }
  .seat .title-box .inner .title::after {
    content: "seat"; }

.seat-list .item {
  margin-bottom: 2%; }

.seat-title {
  text-align: center;
  font-size: 2.5rem;
  position: relative;
  margin: 7rem auto; }
  .seat-title::before {
    content: "";
    width: 11rem;
    height: 134%;
    border: 1px solid #fff;
    position: absolute;
    top: 44%;
    left: 49.5%;
    transform: translate(-50%, -50%); }
  .seat-title::after {
    content: "";
    width: 11rem;
    height: 134%;
    border: 1px solid #fff;
    position: absolute;
    top: 59%;
    left: 50%;
    transform: translate(-50%, -50%); }

.seat-list .title {
  font-size: 2rem;
  text-align: center;
  font-weight: normal; }

.sushi .cnt {
  height: 33rem; }

.other .cnt {
  height: 33rem; }

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .about-txt p {
    font-size: 1.1rem; }
  .about-txt .title {
    font-size: 2rem; } }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .other .cnt {
    height: 27rem; }
  .drink .menu-box .cnt {
    height: 29rem; }
  .drink .menu-box .sake {
    height: 35rem; }
  .menu-box .od3 {
    margin-left: 2rem; }
  .about {
    margin: 0 auto; }
  .sushi .cnt {
    height: 27rem; } }

@media screen and (min-width: 1000px) and (max-width: 1373px) {
  .menu-box .od1 {
    order: 2; }
  .menu-box .od2 {
    order: 1; }
  .menu-box .od3 {
    order: 3; }
    .menu-box .od3 .cnt {
      height: 23rem; }
  .sushi .cnt {
    height: 28rem; }
  #sashimi .menu-box .item {
    height: 16rem; } }

@media screen and (max-width: 980px) {
  .about {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
    order: 2;
    flex-wrap: wrap;
    padding-bottom: 2rem;
    border-bottom: 1px solid; }
    .about .item-l {
      flex-basis: 100%;
      order: 2; }
    .about .item-r {
      flex-basis: 100%;
      margin: 0;
      writing-mode: initial;
      white-space: normal; }
      .about .item-r .title {
        margin: 0;
        font-size: 7.8vw; }
  .title-box .inner {
    padding: 1rem;
    width: 80%; }
    .title-box .inner .title::before {
      width: 3rem;
      height: 3rem; }
    .title-box .inner .title span {
      line-height: 1; }
  .menu-title {
    font-size: 11vw;
    top: -6rem;
    margin-top: 20rem;
    letter-spacing: 12px; }
    .menu-title::before {
      left: 48.5%;
      top: 46%;
      width: 5.5rem;
      height: 136%; }
    .menu-title::after {
      width: 5.5rem;
      height: 136%;
      top: 53%; }
  .menu-image-box {
    position: relative;
    margin: 9rem auto; }
  #about {
    margin: 4rem auto 11rem; }
  .about-cnt img {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .about-cnt .caption {
    font-size: 1rem; }
  .about2 {
    display: block;
    order: 2;
    flex-wrap: wrap; }
    .about2 .item-l {
      margin-right: 0rem;
      order: 2;
      flex-basis: 100%; }
      .about2 .item-l img {
        margin: 2rem auto; }
      .about2 .item-l .title {
        font-size: 8.0826vw; }
    .about2 .item-r {
      flex-basis: 100%; }
  .menu-box > .item {
    width: 100%;
    margin: 0; }
    .menu-box > .item:first-child .list {
      margin: 2rem 0 2rem 1.75rem; }
    .menu-box > .item .cnt {
      writing-mode: unset;
      white-space: normal;
      height: auto; }
    .menu-box > .item .list {
      margin: 2rem 0 2rem 1.75rem; }
      .menu-box > .item .list .item {
        margin: 1rem auto;
        letter-spacing: 2px; }
        .menu-box > .item .list .item .item2 {
          flex-shrink: 0;
          margin-left: 1rem; }
  .menu-box .title {
    border-right: none;
    border-left: 1px solid;
    padding-right: 0;
    padding-left: 1rem;
    margin-right: 0;
    margin-left: .75rem;
    font-size: 9.5vw; }
  .menu-list {
    padding: 5rem 1rem 2rem; }
  .title-box {
    position: relative;
    margin: 7rem auto; }
  .seat-list {
    display: block; }
    .seat-list figure {
      margin-bottom: 2rem; }
    .seat-list .figcaption {
      padding: 0; }
  .seat-title {
    margin: 4rem auto; }
    .seat-title::before {
      left: 48.5%; }
    .seat-title::after {
      left: 51%; }
  .title-box-wrapper {
    margin: 16rem auto 6rem; }
  .enkai {
    padding: 0;
    height: 93vw;
    margin-bottom: 4rem; }
    .enkai .cnt .text {
      font-size: 4vw;
      margin-bottom: 6.25vw; }
    .enkai .cnt .title {
      font-size: 9.75vw;
      margin-bottom: 3vw;
      font-weight: normal; }
    .enkai .cnt .s-text {
      font-size: 5.5vw;
      margin-bottom: 3.5vw; } }

@media screen and (max-width: 980px) {
  .dn {
    display: block; }
  .tl-section {
    padding: 4rem 0; } }
