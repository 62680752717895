//==========================================================
//	メディアクエリ
//==========================================================
@mixin mq($breakpoint: sp) {
	@if $breakpoint == sp {
		@media screen and (max-width: 980px) {
			@content;
		}
	} @else if $breakpoint == slaptop {
		@media screen and (min-width: 1200px) and (max-width: 1439px) {
			@content;
		}
	} @else if $breakpoint == ssp {
		@media screen and (max-width: 600px) {
			@content;
		}
	} @else if $breakpoint == laptop {
		@media screen and (min-width: 1440px) and (max-width: 1599px) {
			@content;
		}
	} @else if $breakpoint == max {
		@media screen and (min-width: 1600px) {
			@content;
		}
	} @else if $breakpoint == wtab {
		@media screen and (min-width: 1000px) and (max-width: 1199px) {
			@content;
		}
	} @else if $breakpoint == tab {
		@media screen and (min-width: 768px) and (max-width: 1024px) {
			@content;
		}
	} @else if $breakpoint == custom {
		@media screen and (min-width: 1000px) and (max-width: 1373px) {
			@content;
		}
	}
}
