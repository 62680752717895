//==========================================================
//	position
//==========================================================
// position
.centering {
	position: absolute;
	z-index: 10;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	text-align: center;
	&Y {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	&X {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
	&_fix {
		@extend .centering;
		position: fixed;
	}
}

// fixed center
.centering_fix {
	position: fixed;
	z-index: 9999;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}


// fixed 100%
.fiexed_100 {
	position: fixed;
	z-index: 9999;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
}
