.enkai {
	background: url(../images/footer-enkai.jpg) center;
	background-size: cover;
	position: relative;
	padding: 12vw;
	margin-bottom: 8rem;
	.cnt {
		width: 100%;
		padding: 1rem;
		@extend .centering;
		.title {
			font-size: 2.75vw;
			margin-bottom: 1vw;
			font-weight: normal;
		}
		.text {
			font-size: 1.25vw;
			margin-bottom: 2.25vw;
			font-family: $basefont;
		}
		.s-text {
			font-size: 1.5vw;
			margin-bottom: 0.5vw;
			display: inline-block;
		}
	}
}


.footer-cnt {
	display: flex;
	justify-content: space-between;
	text-align: center;
	margin-bottom: 8rem;
	.item1,.item3 {
		flex-basis: 28%;
	}
	.item2 {
		flex-grow: 1;
		margin: 0 4%;
	}
	.item {
		iframe {
			height: 19rem;
		}
		img {
			width: 100%;
		}
		.title {
			font-weight: normal;
			font-size: 2vw;
			border-left: 1px solid;
			border-right: 1px solid;
		}
		.text {
			margin: 2rem auto;
		}
	}
}


.holiday-titles {
	display: flex;
}


.footer-address {
	background: url(../images/footer-addressbg.png) center;
	background-size: cover;
	position: relative;
	padding: 16vw;
	.cnt {
		width: 100%;
		padding: 1rem;
		@extend .centering;
		.title {
			font-size: 2.75vw;
			margin-bottom: 1vw;
			font-weight: normal;
		}
		.text {
			font-size: 1.25vw;
			margin-bottom: 2.25vw;
			font-family: $basefont;
		}
		.logo {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 3rem;
			.item-l {
				margin-right: 2rem;
			}
			.logo-text {
				font-size: 1.5rem;
			}
		}
	}
}

.address {
	margin-bottom: 2rem;
	p {
		margin-bottom: .5rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

@include mq {
	.footer-cnt {
		display: block;
		.item {
			margin: 0 0 2rem;
			&:last-child {
				margin-bottom: 0;
			}
			.title {
				font-size: 5vw;
			}
		}
	}
	.footer-address {
		padding: 0;
		height: 137vw;
		.cnt {
			.logo {
				display: block;
				.item-l {
					margin: 0 auto 2rem;
				}
				.item-r {
					img {
						margin: auto;
					}
				}
			}
		}
	}
}
