	
// .pace {
// 	pointer-events: none;
// 	user-select: none;
// }

// .pace-inactive {
// 	display: none;
// }

// .pace {
// 	.pace-progress {
// 		background: rgb(255,220,0);
// 		background: linear-gradient(45deg, rgba(255,220,0,1) 0%, rgba(255,198,37,1) 7%, rgba(255,225,116,1) 15%, rgba(255,252,113,1) 22%, rgba(255,232,48,1) 31%, rgba(255,201,79,1) 50%, rgba(255,243,31,1) 71%, rgba(255,247,100,1) 80%, rgba(255,220,0,1) 91%, rgba(255,207,0,1) 100%);
// 		position: fixed;
// 		z-index: 10000;
// 		top: 0%;
// 		right: 100%;
// 		width: 100%;
// 		height: 4px;
// 	}
// }

.loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0f0600;
	z-index: 1000000;
	// transition: all 1s .8s;
	// background-image: url(../images/loading-bg.jpg);
	// background-position: center;
	// background-size: cover;
	&.open {
		top: -100%;
	}
	.cnt {
		width: 25rem;
		max-width: 70%;
		@extend .centering;
	}
}
.loading-logo {
	img {
		margin: 0 auto;
	}
}
#loadingContainer {
	margin: 20px auto;
	width: 92%;
	height: 2px;
	position: relative;
}
.progressbar-text {
	text-align: center;
	width: 100%;
	margin-top: 1.5rem !important;
}
.curtain {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	transition: all 1s 1s;
	z-index: 9998;
	&.open {
		top: -100%;
	}
}

@include mq {
	.pace {
		display: none;
	}
}
