.flex-gird {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	order: 3;
}
.fg-item1 {
	// flex-basis: 35%;
	// .cnt {
	// 	background-image: url(../images/section/about/img1.jpg);
	// 	background-position: center;
	// 	background-size: cover;
	// 	height: 100%;
	// }
	// .en-title {
	// 	font-size: 3rem;
	// 	color: #ffd858;
	// }
	// .flex-vertical {
	// 	height: 100%;
	// 	.item2 {
	// 		flex-grow: 1;
	// 	}
	// }
}
.fg-item2 {
	flex-basis: 61%;
	.about-txt {
		width: 100%;
		padding: 3rem;
	}
	.img2 {
		background-image: url(../images/section/about/img2.jpg);
		background-position: center;
		background-size: cover;
		padding-top: 41%;
	}
}
.fg-item3 {
	flex-basis: 100%;
	margin-top: 4%;
}


#about {
	margin: 16rem auto;
}
.about {
	display: flex;
	justify-content: center;
	margin-bottom: 10rem;
	.item-r {
		margin: 0 4rem;
		.title {
			font-size: 3rem;
			letter-spacing: .4vw;
			font-weight: normal;
			margin: 0 4rem;
		}
	}
	.item-l {
		flex-basis: 50%;
	}
}
.about-cnt {
	img {
		margin-top: 6rem;
		margin-bottom: 2rem;
	}
	.caption {
		line-height: 2;
		letter-spacing: 1px;
		font-size: 1.1rem;
	}
}
.about-txt {
	background-image: url(../images/section/about/bg.jpg);
	margin-bottom: 7%;
	color: #333;
	height: 53%;
	white-space: normal;
	p {
		font-size: 1.5rem;
		margin: 0 1rem;
		line-height: 2;
	}
	.title {
		font-size: 2.5rem;
		letter-spacing: 2px;
		margin-left: 1.5rem;
	}
}

.about2 {
	max-width: 1080px;
	margin: 0 auto;
	align-items: center;
	.title {
		font-size: 1.8vw;
		font-weight: normal;
		margin-bottom: 1rem;
		letter-spacing: 4px;
	}
	.caption {
		line-height: 2;
		letter-spacing: 1px;
		font-size: 1.1rem;
	}
	.item-l {
		margin-right: 4rem;
	}
}

.fg-i3-item1 {
	flex-basis: 35%;
	&.img1 {
		background-image: url(../images/section/about/img3.jpg);
		background-position: center;
		background-size: cover;
		padding-top: 20%;
	}
}
.fg-i3-item2 {
	flex-grow: 1;
	&.img2 {
		background-image: url(../images/section/about/img4.jpg);
		background-position: center;
		background-size: cover;
		margin: 0 4%;
	}
}
.fg-i3-item3 {
	flex-basis: 35%;
	&.img3 {
		background-image: url(../images/section/about/img5.jpg);
		background-position: center;
		background-size: cover;
	}
}

.title-box-wrapper {
	margin: 24rem auto 6rem;
}
.title-box {
	position: relative;
	margin: 12rem auto;
	&::after {
		content: "";
		height: 1px;
		background-color: #fff;

	}
	.inner {
		@extend .centering;
		background-color: $black-bg;
		padding: 4rem;
		.title {
			font-weight: normal;
			span {
				margin: 2rem auto 1rem;
				display: block;
				font-size: 3rem;
			}
			&::before {
				content: "";
				background-image: url(../images/parts/title-icon.svg);
				background-position: center;
				background-size: contain;
				width: 6rem;
				height: 6rem;
				margin: 0 auto;
			}
			&::after {
				content: "menu";
				
			}
			&.seat {
				&::after {
					content: "seat";
				}
			}
		}
	}
}

.menu-image-box {
	position: relative;
	margin: 15rem auto;
}
.menu-title {
	width: 100%;
	display: flex;
	align-items: center;
	font-size: 4rem;
	font-weight: normal;
	position: relative;
	z-index: 2;
	top: 4rem;
	&::before {
		content: "";
		width: 7rem;
		height: 175%;
		border: 1px solid #fff;
		position: absolute;
		top: 44%;
		left: 49.5%;
		transform: translate(-50%, -50%);
	}
	&::after {
		content: "";
		width: 7rem;
		height: 175%;
		border: 1px solid #fff;
		position: absolute;
		top: 55.5%;
		left: 50.5%;
		transform: translate(-50%, -50%);
	}
}
.menu-list {
	border: 1px solid;
	padding: 6rem 4rem;
	position: relative;
	letter-spacing: 4px;
	&::before {
		content: "";
		width: 2px;
		height: 4rem;
		background-color: #fff;
		top: -30px;
		@extend .centeringX;
	}
	&::after {
		content: "";
		width: 2px;
		height: 4rem;
		background-color: #fff;
		bottom: -32px;
		@extend .centeringX;
	}
	.en-title {
		img {
			margin: auto;
			width: 9rem;
		}
	}
}
.slider {
	margin-bottom: 6rem;
}


.menu-box {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	order: 3;
	margin-top: 2rem;
	.title {
		font-size: 2.5rem;
		display: inline-block;
		line-height: 1;
		border-right: 1px solid #fff;
		padding-right: 1rem;
		margin-right: 1rem;
		font-weight: normal;
	}
	.list {
		margin: 2rem;
		font-size: 1rem;
		list-style: disc;
		.item {
			margin: 0 .5rem;
		}
	}
	.cnt {
		height: 90%;
		&.sashimi {
			height: 20rem;
		}
	}
	&>.item {
		margin: 2rem 0;
		&:first-child {
			.list {
				margin-left: 0;
			}
		}
		&:last-child {
			.title {
				margin-right: 0;
			}
		}
	}
}
.drink {
	.menu-box {
		.cnt {
			height: 42rem;
		}
	}
}


.seat {
	margin-bottom: 12rem;
	.title-box {
		.inner {
			.title {
				&::after {
					content: "seat";
				}
			}
		}
	}
}
.seat-list {
	.item {
		margin-bottom: 2%;
	}
}
.seat-title {
	text-align: center;
	font-size: 2.5rem;
	position: relative;
	margin: 7rem auto;
	&::before {
		content: "";
		width: 11rem;
		height: 134%;
		border: 1px solid #fff;
		position: absolute;
		top: 44%;
		left: 49.5%;
		transform: translate(-50%, -50%);
	}
	&::after {
		content: "";
		width: 11rem;
		height: 134%;
		border: 1px solid #fff;
		position: absolute;
		top: 59%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.seat-list {
	.title {
		font-size: 2rem;
		text-align: center;
		font-weight: normal;
	}
}


.sushi {
	.cnt {
		height: 33rem;
	}
}


.other {
	.cnt {
		height: 33rem;
	}
}


@include mq(laptop) {
	.about-txt {
		p {
			font-size: 1.1rem;
		}
		.title {
			font-size: 2rem;
		}
	}
}

@include mq(tab) {
	.other .cnt {
		height: 27rem;
	}
	.drink .menu-box {
		.cnt {
			height: 29rem;
		}
		.sake {
			height: 35rem;
		}
	}
	.menu-box {
		.od3 {
			margin-left: 2rem;
		}
	}
	.about {
		margin: 0 auto;
	}
	.sushi .cnt {
		height: 27rem;
	}
}

@include mq(custom) {
	.menu-box {
		.item {
			// height: 24rem;
		}
		.od1 {
			order: 2;
		}
		.od2 {
			order: 1;
		}
		.od3 {
			order: 3;
			.cnt {
				height: 23rem;
			}
		}
	}
	.sushi .cnt {
		height: 28rem;
	}
	#sashimi {
		.menu-box {
			.item {
				height: 16rem;
			}
		}
	}
}

@include mq {
	.about {
		display: flex;
		justify-content: center;
		margin-bottom: 4rem;
		order: 2;
		flex-wrap: wrap;
		padding-bottom: 2rem;
		border-bottom: 1px solid;
		.item-l {
			flex-basis: 100%;
			order: 2;
		}
		.item-r {
			flex-basis: 100%;
			margin: 0;
			writing-mode: initial;
			white-space: normal;
			.title {
				margin: 0;
				font-size: 7.8vw;
			}
		}
	}
	.title-box .inner {
		padding: 1rem;
		width: 80%;
		.title {
			&::before {
				width: 3rem;
				height: 3rem;
			}
			span {
				line-height: 1;
			}
		}
	}
	.menu-title {
		font-size: 11vw;
		top: -6rem;
		margin-top: 20rem;
		letter-spacing: 12px;
		&::before {
			left: 48.5%;
			top: 46%;
			width: 5.5rem;
			height: 136%;
		}
		&::after {
			width: 5.5rem;
			height: 136%;
			top: 53%;
		}
	}
	.menu-image-box {
		position: relative;
		margin: 9rem auto;
	}
	#about {
		margin: 4rem auto 11rem;
	}
	.about-cnt {
		img {
			margin-top: 2rem;
			margin-bottom: 2rem;
		}
		.caption {
			font-size: 1rem;
		}
	}
	.about2 {
		display: block;
		order: 2;
		flex-wrap: wrap;
		.item-l {
			margin-right: 0rem;
			order: 2;
			flex-basis: 100%;
			img {
				margin: 2rem auto;
			}
			.title {
				font-size: 8.0826vw;
			}
		}
		.item-r {
			flex-basis: 100%;
		}
	}
	.menu-box {
		& > .item {
			width: 100%;
			margin: 0;
			&:first-child {
				.list {
					margin: 2rem 0 2rem 1.75rem;
				}
			}
			.cnt {
				writing-mode: unset;
				white-space: normal;
				height: auto;
			}
			.list {
				margin: 2rem 0 2rem 1.75rem;
				.item {
					margin: 1rem auto;
					letter-spacing: 2px;
					.item2 {
						flex-shrink: 0;
						margin-left: 1rem;
					}
				}
			}
		}
		.title {
			border-right: none;
			border-left: 1px solid;
			padding-right: 0;
			padding-left: 1rem;
			margin-right: 0;
			margin-left: .75rem;
			font-size: 9.5vw;
		}
	}
	.menu-list {
		padding: 5rem 1rem 2rem;
	}
	.title-box {
		position: relative;
		margin: 7rem auto;
	}
	.seat-list {
		display: block;
		figure {
			margin-bottom: 2rem;
		}
		.figcaption {
			padding: 0;
		}
	}
	.seat-title {
		margin: 4rem auto;
		&::before {
			left: 48.5%;
		}
		&::after {
			left: 51%;
		}
	}
	.title-box-wrapper {
		margin: 16rem auto 6rem;
	}
	.enkai {
		padding: 0;
		height: 93vw;
		margin-bottom: 4rem;
		.cnt {
			.text {
				font-size: 4vw;
				margin-bottom: 6.25vw;
			}
			.title {
				font-size: 9.75vw;
				margin-bottom: 3vw;
				font-weight: normal;
			}
			.s-text {
				font-size: 5.5vw;
				margin-bottom: 3.5vw;
			}
		}
	}
}
