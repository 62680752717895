// header
.header {
	background-image: url(../images/hero.jpg);
	background-position: center;
	background-size: cover;
	height: 100vh;
	transition: all .4s;
	padding-top: 3rem;
	position: relative;
	margin-bottom: 10%;
	.logo {
		
	}
}
.header-menu {
	margin-right: 3rem;
	font-size: 1.5rem;
	.menu-item {
		margin: 0 1rem;
	}
}
.header-submenu {
	font-size: 1rem;
	margin-top: 1rem;
	a {
		margin: 0 .5rem;
		&::before {
			content: "";
			display: inline-block;
			width: 1px;
			height: .5rem;
			margin-bottom: .5rem;
			background-color: #fff;
		}
	}
}
.article {
	position: absolute;
	bottom: -10%;
	width: 800px;
	@extend .centeringX;
	.title {
		text-align: center;
		font-size: 2.5rem;
		margin-bottom: 1.5rem;
		text-shadow: 1px 1px 6px #000;
		letter-spacing: 2px;
	}
}
.article-cnt {
	align-items: center;
}
.article-container {
	background: #fff;
	color: #000;
	padding: 1.5rem;
}
.article-thumb {
	background-position: center;
	background-size: cover;
	margin-right: 1rem;
	min-height: 12rem;
}
.article-title {
	font-size: 2.5rem;
	line-height: 1.4;
	font-family: $basefont;
	font-weight: normal;
}


.info {
	font-size: .75rem;
	font-family: $basefont;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 1rem;
	.item {
		line-height: 1;
		padding: 0.5rem;
		margin-right: .5rem;
		margin-bottom: .5rem;
		color: #313131;
		background: rgba(255, 255, 255, 0.8);
	}
}


@include mq(tab) {
	.header-container {
		margin: 0 auto;
	}
	.header-menu {
		margin-right: 2rem;
		margin-left: 2rem;
		font-size: 2.5vw;
	}
	.header {
		height: 50vw;
		position: static;
	}

}

@include mq {
	.sns-fix {
		display: none;
	}
	.hero {
		.item2 {
			width: 100%;
			.inner {
				.btn-wrap {
					margin: 0;
				}
			}
		}
	}
	.hero-cnt {
		margin: 2rem auto;
	}
	.header {
		height: 70vh;
		padding: 2rem;
		background-image: url(../images/hero2.jpg);
	}
	.header-container {
		max-width: 100%;
	}
	.mobile-header {
		background: $black-bg;;
		padding: 7vw;
		transition: all .6s;
		transform: translateX(-100vw);
		white-space: normal;
		img {
			width: 100%;
		}
		&.on {
			transform: translateX(0);
		}
		.header-menu {
			margin-bottom: 1rem;
			list-style: disc;
			margin-left: 1.8rem;
			.menu-item {
				margin: 1rem auto;
			}
		}
		.info {
			margin: 1rem auto;
		}
	}
	.header-submenu {
		a {
			&::before {
				width: .75rem;
				height: 1px;
				margin-bottom: .5rem;
				position: relative;
				top: 3px;
				margin-right: 1rem;
			}
		}
		.sm-item {
			margin: .5rem auto;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.header-bg--container {
		padding: 1.5rem 0;
	}
	.header-container {
	}
	.mb-menu-wrap {
		position: fixed;
		top: 0;
		left: 0;
		transform: translateX(-100vw);
		width: 100%;
		height: 100%;
		background: rgba(13, 15, 37, 0.98);
		z-index: 99999;
		transition: all .6s;
		padding: 2rem;
		overflow: auto;
		&.open {
			transform: translateX(0);
		}
	}
	// .header {
	// 	position: fixed;
	// 	top: 0;
	// 	left: 0;
	// 	z-index: 9;
	// 	padding: 1rem;
	// 	background: #000;
	// 	.logo {
	// 		width: 6rem;
	// 	}
	// }
	.article {
		width: 100%;
		position: static;
		transform: none;
	}
	.article-title {
		font-size: 1.25rem;
	}
	.article-cnt {
		display: block;
	}
	.article-thumb {
		margin-right: 0;
		margin-bottom: 1rem;
	}
}
